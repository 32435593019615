<template>
  <div>
    <b-card
      no-body
      class="saj-title"
      style=""
    >
      <validation-observer ref="validateSubcore">
        <b-card-header class="p-1 justify-content-center">
          <h5
            class="saj-header "
            style=""
          >
            {{ isUpdate ? $t("Edit Sub Behavioural") : $t("Add New Sub Behavioural") }}
            <!-- {{ behaviourData.id }} -->
            <!-- {{ behaviourId }} -->
          </h5>
        </b-card-header>
        <b-card-body>
          <div class="col-lg">
            <div
              class="card"
              style="
            "
            >
              <!--Sub Behavior Details -->
              <validation-observer ref="validateSubcore">
                <b-row
                  class="p-1 mb-2"
                  style="
                          color: black;
                          background: #f7f7fc;
                          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                          border-radius: 15px;
                          height: 100%;
                        "
                >
                  {{ $t("Sub Behavioural") }}
                  <b-row class="col-12 saj-text p-1 m-0">
                    <!-- Behavioural English -->
                    <b-col
                      cols="2"
                      class="p-1 d-flex align-items-start"
                    >
                      {{ $t("In English") }}:
                    </b-col>
                    <b-col
                      cols="10"
                      class="p-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('In English')"
                        rules="required"
                      >
                        <textarea
                          v-model="behaviourData.name_bi"
                          class="saj-text form-control flex-grow-1"
                          name="textarea"
                          style="height: 120px
                                  border: 1px solid #d8d6de;
                                  "
                          rows="5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <!-- Behavioural Malay -->
                    <b-col
                      cols="2"
                      class="p-1 d-flex align-items-center"
                    >
                      {{ $t("In Malay") }}:
                    </b-col>
                    <b-col
                      cols="10"
                      class="p-1 "
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('In Malay')"
                        rules="required"
                      >
                        <textarea
                          v-model="behaviourData.name_bm"
                          class="saj-text form-control flex-grow-1"
                          name="textarea"
                          style="height: 120px
                                  border: 1px solid #d8d6de;
                                  "
                          rows="5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-row>
                <!-- Sub Behavior Details End-->

                <!-- Visibility -->
                <!-- <b-row
                  v-if="isUpdate"
                  class="p-1"
                  style="
                          color: black;
                          background: #f7f7fc;
                          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                          border-radius: 15px;
                          height: 100%;
                        "
                >
                  {{ $t("Visibility") }}
                  <b-row class="col-12 saj-text p-1 m-0">
                    <b-col
                      cols="2"
                      class="p-1 d-flex align-items-center"
                    >
                      {{ $t("Subsidiary") }}:
                    </b-col> -->
                <!-- <b-col
                      cols="10"
                      class="p-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Subsidiary')"
                        rules="required"
                      >
                        <v-select
                          v-model="selectVisibility"
                          class="saj-title select-size-md"
                          style="background: white; "
                          label="text"
                          :placeholder="$t('Select Subsidiary')"
                          multiple
                          :reduce="val => val.value"
                          :options="behaviorVisibilityList"
                          :value="behaviorVisibilityList"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col> -->
                <!-- <b-col
                      v-if="!isUpdate"
                      cols="10"
                      class="p-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Subsidiary')"
                        rules="required"
                      >
                        <v-select
                          v-model="selectVisibility"
                          class="saj-title select-size-md"
                          style="background: white; "
                          label="text"
                          :placeholder="$t('Select Subsidiary')"
                          multiple
                          :reduce="val => val.value"
                          :options="behaviorVisibilityList"
                          :value="behaviorVisibilityList"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>-->
                <!-- <b-col>
                      <div
                        class="pb-1 pl-1"
                        style="   background-color: #fff;
                                  background-clip: padding-box;
                                  border: 1px solid #d8d6de;
                                  border-radius: 0.357rem;
                              "
                      >
                        <b-badge
                          v-for="(x, idx) in selectVisibility"
                          :key="idx"
                          variant="primary"
                          class="mr-1 mt-1"
                        >
                          {{ x.text }}
                        </b-badge>
                      </div></b-col>
                  </b-row></b-row></validation-observer> -->

                <!-- Visibility End -->
                <div
                  class="d-flex justify-content-center mt-2"
                  style=""
                >
                  <button
                    class="btn btn-close m-1 saj-button"
                    aria-label="Close modal"
                    style="

                  color: white;
                  background: #ff0000;
                "
                    @click="close()"
                  >
                    {{ $t('Cancel') }}
                  </button>
                  <button
                    class="btn m-1 saj-button"
                    aria-label="submit modal"
                    style="

                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
                    @click="isUpdate ? editSubBehav() : addSubBehav()"
                  >
                    {{ $t('Save') }}
                  </button>
                </div>
              </validation-observer></div></div></b-card-body></validation-observer></b-card></div>

</template>
<style>
</style>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  // BFormInput,
  // BBadge,

} from "bootstrap-vue"
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    // BFormInput,
    // vSelect,
    ValidationProvider,
    ValidationObserver,
    // BBadge,
  },
  props: {
    behaviourData: {
      type: null,
      default: null,
    },
    isUpdate: {
      type: Boolean,
      default: true,
    },
    behaviourId: {
      type: String,
      default: null,
    },

  },
  data() {
    return {
      required,
      selectVisibility: [],
      subList: [],
      subcoreInfo: [],
      behaviorVisibilityList: [],
      subsidiaryIdList: [],
    }
  },
  mounted() {
    setTimeout(() => {
      this.getSubsidiaryAll()

      if (this.isUpdate){
        this.getVisibilityBehaviour()
      }
    }, 500)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getVisibilityBehaviour(){
      this.$axios.get(`${this.$baseUrl}/competency_behaviour/get_sub_behaviour_by_id?sub_behaviour_id=${this.behaviourData.id}`).then(response => {
        const res = response.data.data
        this.selectVisibility = res.visible_to_subsidiary_id
        if (this.isUpdate){
          setTimeout(() => {
            this.visibleSub()
          }, 500)
        }
        // console.log('visibility', this.selectVisibility)
      })
    },
    visibleSub(){
      this.selectVisibility = this.behaviorVisibilityList.filter(item => this.selectVisibility.includes(item.value))
    },
    addSubBehav(){
      this.$refs.validateSubcore.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('competency_behaviour_id', this.behaviourId)
          data.append('name', this.behaviourData.name_bm)
          data.append('name_bi', this.behaviourData.name_bi)
          this.subsidiaryIdList.forEach(sub => {
            data.append('visible_to_subsidiary_id[]', sub)
          })
          // data.append('visible_to_subsidiary_id[]', this.selectVisibility)

          // data.forEach(a => {
          //   console.log("append add", a)
          // })
          // console.log(this.isUpdate)
          this.$axios.post(`${this.$baseUrl}/competency_behaviour/create_subbehaviour_content`, data)
            .then(() => {
              // data.forEach(a => {
              //   console.log("append", a)
              // })
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Sub Behaviour successfully added')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
                this.$emit('reload-subbehaviour')
                this.$emit('close')
              }, 1000)
            })
        }
      })
    },
    editSubBehav(){
      this.$refs.validateSubcore.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('sub_behaviour_id', this.behaviourData.id)
          data.append('name', this.behaviourData.name_bm)
          data.append('name_bi', this.behaviourData.name_bi)
          this.subsidiaryIdList.forEach(sub => {
            data.append('visible_to_subsidiary_id[]', sub)
          })
          // this.selectVisibility.forEach(sub => {
          //   data.append('visible_to_subsidiary_id[]', sub)
          // })

          // data.forEach(a => {
          //   console.log("append edit", a)
          // })
          // console.log(this.isUpdate)
          this.$axios.post(`${this.$baseUrl}/competency_behaviour/update_subbehaviour_content`, data)
            .then(() => {
              // data.forEach(a => {
              //   console.log("append", a)
              // })
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Sub Behaviour successfully updated')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
                this.$emit('reload-subbehaviour')
                this.$emit('close')
              }, 1000)
            })
        }
      })
    },
    getSubsidiaryAll() {
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allSubsideriesName = response.data.data.subsidiaries
        this.allSubsideriesName.forEach(nameSub => {
          this.behaviorVisibilityList.push({
            value: nameSub.id,
            text: nameSub.subsidiary_name,
          })
        })
        this.subsidiaryIdList = this.allSubsideriesName.map(x => (
          x.id
        ))
      })
    },

  },
}
</script>
